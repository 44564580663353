import '../scss/privacy.scss';

function TermPage() {

  return (
    <div  className="body-inner" id="termcondition">
      <section id="main-container" className="infoContainer">
  <div className="container privacySecBox">
    <div className="row">
        <div className="col-lg-12 who-we-are">
          <h4 className="secTitle">PLEASE READ THESE TERMS AND CONDITIONS OF SERVICE BEFORE CONTACTING VVVFOUNDATIONS</h4>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          
          <p className='boxText'><b>Price Policy.</b> </p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
        
          <p className='boxText'><b></b> </p>
          <p className='boxText'></p>
          <p className='boxText'></p>
          <p className='boxText'></p>
        
          <p className='boxText'><b></b> </p>
           <p> <li></li>
           <li></li>
           <li></li>
           <li></li>
            </p>
         
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
    </div>
  )
}

export default TermPage