import '../scss/home.scss';
import styled from "styled-components";
import { imageZoomEffect, TitleStyles } from "../services/ReusableStyle";
import {  myBlogs, ourDirectors, ourProjects, testVideo } from '../services/impImages';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import{FaPhoneAlt } from 'react-icons/fa';

function HomePage() {
  let navigate=useNavigate();
  const handleClick = (e) => navigate('/#');//toast("Currently Myvisapriority.com doesn't provide online visa application to " + e.target.name + ", Kindly call us to get receive more information from our Visa Specialists, Please contact us on +971 55 636 8548");

  const slides = [
    {id:1, url: 'assets/slider-main/bg4_4.jpg' },
    { id:2,url: 'assets/slider-main/bg4_2.jpg' },
    { id:3,url: 'assets/slider-main/bg8.jpg' },
    { id:4,url: 'assets/slider-main/bg4.jpg' },
    { id:5,url: 'assets/slider-main/bg10.jpg' }
  ];

  

  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  let settings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  let testimonialSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  let videoSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  
  return (
    <div className="body-inner" id="home">
    <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='topHead'>
      <div className='topText' >
        <h6><i style={{color:'lightgreen'}}>GIVE A HAND</i></h6>
        <h3><i style={{color:'lightgreen'}}>TO FIGHT AGAINST POVERTY & CREATING JOBS</i></h3>
      </div>
    </div>
      <ToastContainer style={{ width: '100%' }} />
      <div className='containerStyles'>
      <Slider {...mainSettings}>
      {slides.map((image) => (
                      <img loading="lazy" key={image.id} className='imgContainer' src={image.url} alt='Ratchu Software' />
              ))}
      </Slider>
      </div>
    </section>

    <div className="gap-40"></div>
    
    <Section id="facts" className="secFact" >
      <div className="container">
        <div className="row secFactsRow">
        <div className="col"></div>
          <div className="col-md-2 col-sm-6">
            <div className="ts-facts-img secFacts">
              <img loading="lazy" src="./assets/icon-image/villiage.png" alt="facts-img" />
              <h3 className="tsFactsTitle"> Join Our</h3>
              <a href={`#`} className='infoText'>
            Friends of Mercy Network
             </a>
            </div>
          </div>

          <div className="col-md-2 col-sm-6">
            <div className="ts-facts-img secFacts">
              <img loading="lazy" src="./assets/icon-image/educate-w.png" alt="facts-img" />
              <h3 className="tsFactsTitle">Social</h3>
              <a href={`#'}`} className='infoText'>
            Investment to women Entrepreneurs
             </a>
            </div>
          </div>

          <div className="col-md-2 col-sm-6">
            <div className="ts-facts-img secFacts">
              <img loading="lazy" src="./assets/icon-image/livehood.png" alt="facts-img" />
              <h3 className="tsFactsTitle">Direct Support</h3>
              <a href={`#`} className='infoText'>
              Small Scale Farmer for Cultivation 
             </a>
            </div>
          </div>

          <div className="col-md-2 col-sm-6">
            <div className="ts-facts-img secFacts">
              <img loading="lazy" src="./assets/icon-image/children.png" alt="facts-img" />
              <h3 className="tsFactsTitle">Help</h3>
              <a href={`#`} className='infoText'>
             Rural Child For Education
             </a>
            </div>
          </div>
          <div className="col-md-2 col-sm-6">
            <div className="ts-facts-img secFacts">
              <img loading="lazy" src="./assets/icon-image/save-nature.png" alt="facts-img" />
              <h3 className="tsFactsTitle">Support for </h3>
              <a href={`#`} className='infoText'>
              Rural water Management
             </a>
            </div>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </Section>
    
      <Section id="facts" className="infoContainer">
    <div className="container">
      <div className='quoteItem'>
    <h2 className="quoteTitle">Our Services</h2>
    <Slider {...testimonialSettings}>
        
            <div className="quoteText">
            <h3 className='boxTitle'>Mission</h3>
             <div className="d-flex">
                  <img loading="lazy" src="./assets/icon-image/proverty.png" className='serviceImg' alt="service-icon" />
             Fighting against poverty and making poverty free families by social enterprises
             </div>
             <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/livehood.png" className='serviceImg' alt="service-icon" />
             Sustainable livelihood support to small scale farmers by direct marketing
             </div>
             <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/educate-w.png" className='serviceImg' alt="service-icon" />
             To educate women as entrepreneurs’ in Agriculture and Food Production by skill training
             </div>
             <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/save-nature.png" className='serviceImg' alt="service-icon" />
             Save the Nature for our next generation by Tree Plantation
             </div>
             <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/humanity.png" className='serviceImg' alt="service-icon" />
             Humanity Education to children by evening tuition centres
             </div>
             <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/friends-m.png" className='serviceImg' alt="service-icon" />
             Build Social Network “Friends of Mercy” by social awareness media “Village Voice”
             </div>
           
          </div>
         
            <div className="quoteText">
            <h3 className='boxTitle'>Vision 2025 – 2028</h3>
            <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/proverty.png" className='serviceImg' alt="service-icon" />
             To be accomplish 500 poverty free families at village level in Thirunelveli  District – Tamil Nadu
             </div>
            
             <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/humanity.png" className='serviceImg' alt="service-icon" />
             To create 300 entrepreneurs by Social Enterprises Training
             </div>
             <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/diablity.png" className='serviceImg' alt="service-icon" />
             To be accomplish 100 street petty shop for disabilities
             </div>
             <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/friends-m.png" className='serviceImg' alt="service-icon" />
             To bring 5,000 people as consumers and supporters into our network as “Friends of Mercy” families
             </div>
             <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/villiage.png" className='serviceImg' alt="service-icon" />
             To be published 10 Visual Themes by “Village Voice” media
             </div>
             <div className="d-flex">
              <img loading="lazy" src="./assets/icon-image/children.png" className='serviceImg' alt="service-icon" />
             To bring 5000 children under humanity Education
             </div>
            </div>
         
          </Slider>
      </div>
      
      </div>
    </Section>


    <section className="infoContainer" style={{ backgroundColor: 'green' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="subscribe-call-to-acton">
            <p/>
            </div>
          </div>
        </div>
      </div>
    </section>


    <Section id="facts" className="infoContainer">
    <div className="container">
      <div className='blogItemBox'>
    <h2 className="quoteTitle">Our Projects</h2>
    <Slider {...settings}>
        {ourProjects.map((myBlog) => (
           
            <div className="blogBox" key={myBlog.id}>
             <Link to={'products/'+myBlog.id} style={{ textDecoration: "none" }}>
            <img loading="lazy"  src={myBlog.img} alt={myBlog.name} className='blogImage' />
                <h5 className="blogTitle">{myBlog.name} </h5> 
                </Link>
            </div>
           
        ))}
      </Slider>
      </div>
      
      </div>
    </Section>

    <Section id="facts" className="infoContainer">
    <div className="container">
      <div className='quoteItem'>
    <h2 className="quoteTitle">Villiage Voice</h2>
    <Slider {...videoSettings}>
        {testVideo.map((testimonial) => (
         
            <div className="quoteVideo" key={testimonial.id}>
            <iframe width="100%" height="350" src={testimonial.content} title={testimonial.name} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <div className="quoteFooter">{testimonial.name} </div>
            </div>
          
        ))}
      </Slider>
      </div>
      
      </div>
    </Section>

    <Section id="facts" className="infoContainer">
    <div className="container">
      <div className='blogItemBox'>
    <h2 className="quoteTitle">Mission Directors</h2>
    <Slider {...settings}>
        {ourDirectors.map((myBlog) => (
           
            <div className="blogBox" key={myBlog.id}>
             <Link to={'products/'+myBlog.id} style={{ textDecoration: "none" }}>
            <img loading="lazy"  src={myBlog.img} alt={myBlog.name} />
                <h5 className="blogTitle">{myBlog.name} </h5> 
                <a className="blogLink blogButton" data-no-turbolink="true" target="_blank"  rel="noopener noreferrer" href={myBlog.link} >Read More</a>
                </Link>
            </div>
           
        ))}
      </Slider>
      </div>
      
      </div>
    </Section>

    <Section className="infoContainer">
      <div className="container">
            <div className="homeVisaItemBox">
            <h2 className="quoteTitle">Area of Mission</h2>
            <div className='row'>
              {myBlogs.map((image) => (
                <div className="col-lg-4 col-md-6 mb-5" key={image.id}>
                   <Link to={'otherplaces/'+image.id} style={{ textDecoration: "none" }}>
                  <Tooltip title={image.name.toUpperCase()} className="show">
                    <div className="visaItem">
                      <img loading="lazy" src={image.img} alt={image.name}  onClick={handleClick} />
                      <h5 className="blogTitle">{image.name} </h5>
                    </div>
                  </Tooltip>
                  </Link>
                </div>
              ))}
              </div>
            </div>
      </div>
    </Section>
    


    <section className="infoContainer" style={{ backgroundColor: 'green' }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="subscribe-call-to-acton">
            <p/>
            </div>
          </div>
        </div>



        
      </div>
    </section>
    

  </div>
  )
}

export default HomePage;

const Section = styled.section`
  ${TitleStyles};
  ${imageZoomEffect};
  `;