import { blogDetails } from '../services/impImages';
import '../scss/service.scss';
import styled from "styled-components";
import { imageZoomEffect, TitleStyles } from "../services/ReusableStyle";
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

function PlacesPages() {
  let navigate=useNavigate();
  const handleClick = (e) => navigate('/#');//toast("Currently Myvisapriority.com doesn't provide online visa application to " + e.target.name + ", Kindly call us to get receive more information from our Visa Specialists, Please contact us on +971 55 636 8548");

    const slides=[
        {url:'assets/slider-main/bg4_4.jpg', head:'',title:'',subTitle:''},
      ];
      let mainSettings = {
        infinite: true,
        autoplay:true,
        speed: 1000,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    
        responsive: [
          {
            breakpoint: 960,
            mainSettings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 480,
            mainSettings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      };

  return (
    <div className="body-inner" id="services">
      <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='serviceHomeText'>
                <h1>Our Family</h1>
                <Link to='/' className='homeTextLink'>Home</Link> | Our Family
      </div>
        <ToastContainer style={{width:'100%'}}/>
<div className='mainContainerStyles'>
<Slider {...mainSettings}>
        {slides.map((image) => (
                        <img loading="lazy" className='serviceImgContainer' src={image.url} alt='Ratchu Software' />
                ))}
        </Slider>
</div>
</section>

<Section className="infoContainer">
        <div className="container">
              <div className="homeVisaItemBox">
              <h2 className="quoteTitle">Our Family</h2>
              <div className='row'>
                {blogDetails.map((image) => (
                  <div className="col-lg-4 col-md-6 mb-5" key={image.id}>
                     <Link to={image.id} style={{ textDecoration: "none" }}>
                    <Tooltip title={image.name.toUpperCase()} className="show">
                      <div className="visaItem">
                        <img loading="lazy" src={image.img} alt={image.name}  onClick={handleClick} />
                        <h5 className="blogTitle">{image.name} </h5>
                      </div>
                    </Tooltip>
                    </Link>
                  </div>
                ))}
                </div>
              </div>
        </div>
      </Section>

<section className="infoContainer" style={{background:"green"}}>
  <div className="container">
    <div className="row">
        <div className="col-md-12">
          <div className="subscribe-call-to-acton">
              <p/>
          </div>
        </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default PlacesPages;

const Section = styled.section`
  ${TitleStyles};
  ${imageZoomEffect};
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    padding-top: 1rem;
    `;