
export const productDetails=[
    {
      "id":1,
      "name":"Page Comming Soon",
      "img":"./assets/services/products.gif"
  }
];

export const solutionDetails=[
    {
      "id":1,
      "name":"Page Comming Soon",
      "img":"./assets/services/services.gif"
  }
];

export const ourDirectors=[
    {
      "id":"Arul-George",
      "title":"Mission Director",
      "name":"Mr.Arul M. George",
       "desc":"<p>Arul M. George.</p>",
      "img":"./assets/images/directors/arulgeorge.jpg",
      "link":"#"
  },
  {
    "id":"Amala-Brisco-Bose",
    "title":"Mission Director",
    "name":"Mr. Amala Brisco Bose",
    "desc":"<p>Mr. Amala Brisco Bose</p>",
    "img":"./assets/images/directors/bose.jpg",
    "link":"#"
},
{
    "id":"Anul-lal",
    "title":"Mission Director",
    "name":"Mr. Anul Lal",
    "desc":"Mr. Anul Lal",
    "img":"./assets/images/directors/temp.jpg",
    "link":"#"
},
{
    "id":"Antony",
    "title":"Mission Director",
    "name":"Mr. Antony",
    "desc":"Mr. Antony",
    "img":"./assets/images/directors/antony.jpg",
    "link":"#"
},
{
    "id":"Christopher",
    "title":"Mission Director",
    "name":"Mr. Christopher",
    "desc":"<p>Mr. Christopher</p>",
    "img":"./assets/images/directors/crist.jpg",
    "link":"#"
},
{
    "id":"Vimal",
    "title":"Mission Director",
    "name":"Mr. Vimal",
    "desc":"<p>Mr. Vimal Mission .</p>",
    "img":"./assets/images/directors/vimal.jpg",
    "link":"#"
},
{
    "id":"Era-Ramar",
    "title":"Mission Director",
    "name":"Mr.Era R.R.Ramar",
    "desc":"Mr.Era R.R.Ramar",
    "img":"./assets/images/directors/ram.jpg",
    "link":"#"
},
{
    "id":"Karthick",
    "title":"Mission Director",
    "name":"Mr. Karthick",
    "desc":"Mr. Karthick",
    "img":"./assets/images/directors/temp.jpg",
    "link":"#"
}
];

export const ourProjects=[
    {
      "id":"project01",
      "title":"Poverty Elimination through self employment",
      "name":"Poverty Elimination through self employment",
       "desc":"<p>Poverty Elimination through self employment.</p>",
      "img":"./assets/images/projects/self-e.jpg",
      "link":"#"
  },
  {
    "id":"project02",
    "title":"Skill Training in Social Enterprises",
    "name":"Skill Training in Social Enterprises",
    "desc":"<p>Skill Training in Social Enterprises</p>",
    "img":"./assets/images/projects/skill-trainig.jpg",
    "link":"#"
},
{
    "id":"project03",
    "title":"Humanity Education by Evening Tuition centre",
    "name":"Humanity Education by Evening Tuition centre",
    "desc":"<p>Humanity Education by Evening Tuition centre.</p>",
    "img":"./assets/images/projects/educate-humanity.jpg",
    "link":"#"
},
{
    "id":"project04",
    "title":"Save our nature for our generation",
    "name":"Save our nature for our generation",
    "desc":"<p>Save our nature for our generation.</p>",
    "img":"./assets/images/projects/save-nature.jpg",
    "link":"#"
},
{
    "id":"project05",
    "title":"Food Security to every creature",
    "name":"Food Security to every creature",
    "desc":"Food Security to every creature",
    "img":"./assets/images/projects/food-security.jpg",
    "link":"#"
}
];

export const myBlogs=[
    {
        "id":"theni",
        "name":"Theni District",
        "img":"./assets/images/blog/theni.jpg",
        "desc":"<p>Theni District.</p>",
        "link":"#"
    },
    {
        "id":"thirunelveli",
        "name":"Thirunelveli District",
        "img":"./assets/images/blog/thirunelveli.jpg",
        "desc":"<p>Thirunelveli District.</p>",
        "link":"#"
    },
    {
        "id":"kanyakumari",
        "name":"Kanyakumari District",
        "img":"./assets/images/blog/kan.jpg",
        "desc":"<p>Kanyakumari District.</p>",
        "link":"#"
    },
    {
      "id":"rameswaram",
      "name":"Ramanathapuram District",
      "img":"./assets/images/blog/r1.jpg",
      "desc":"<p>Ramanathapuram District.</p>",
      "link":"#"
  },
  {
    "id":"madurai",
    "name":"Madurai District",
    "img":"./assets/images/blog/mdu.jpg",
    "desc":"<p>Madurai District.</p>",
    "link":"#"
},
{
    "id":"Thenkasi",
    "name":"Thenkasi District",
    "img":"./assets/images/blog/ker.jpg",
     "desc":"<p>Thenkasi District.</p>",
    "link":"#"
}
/*{
    "id":"tiruchirappalli",
    "name":"Tiruchirappalli",
    "img":"./assets/images/blog/tric.jpg",
     "desc":"<p>Trichy is the fourth largest city of Tamil Nadu and the transportation center of the State. It is located on the banks of the River Cauvery on which the first man-made dam KALLANAI was built across. The world famous landmark in Trichy is the 85m tall Rock Fort right in the middle of the City, which used to be a military fort during the Pre-British era. The history of Tiruchirappalli begins in the 3rd century BC, when it was under the rule of the Cholas. It was a stronghold of the early Cholas which later fell to the Pallavas. But the Pallavas never really managed to retain control of this strategic city and lost it to the Pandyas several times. Trichy continued to be in the possession of Cholas until the decline of the empire after which it became a Vijayanagara stronghold. Trichy came under the rule of Nayaks of Madurai after the collapse of Vijayanagara, later to the Marathas of Tanjore, the Nawabs of Carnatic, the French and finally the British. Trichy flourished under Madurai Nayaks and prospered to be the city that it is today. The city played a critical role in the Carnatic Wars (1746-1763) between the British and the French East India companies.</p><p>Tiruchirappalli is home to numerous historical monuments and temples that attract tourist and pilgrims from all parts of the country throughout the year. Ranganathaswamy temple at Srirangam, one of the most famous pilgrimage centers in South India is situated near Trichy. Rockfort Temple, a spectacular monument perched on a massive rock is another important monument in old city of Trichy. The Rockfort, Jambukeswarar temple at Thiruvanaikaval, Uraiyur, Maariamman Temple, Government Museum and the St John’s Church are other attractions in Trichy.</p>",
    "link":"#"
},
{
    "id":"kodaikanal",
    "name":"Kodaikanal",
    "desc":"<p>Kodaikanal is a city in the hills of the Dindigul district in the state of Tamil Nadu, India. Its name in the Tamil languagemeans “The Gift of the Forest”. Kodaikanal is referred to as the “Princess of Hill stations” and has a long history as a retreat and popular tourist destination. Kodaikanal was established in 1845 as a refuge from the high temperatures and tropical diseases of the plains. Much of the local economy is based on the hospitality industry serving tourism.</p><p>Kodaikanal is a hill town in the southern Indian state of Tamil Nadu. It’s set in an area of granite cliffs, forested valleys, lakes, waterfalls and grassy hills. At 2,000 meters above sea level, the town centers around man-made, star-shaped Kodaikanal Lake, bordered by evergreen forest. Rowing boats can be hired, and hikers and cyclists follow the 5k Lake Road path around the shore.</p>",
    "img":"./assets/images/blog/kodai.jpg",
    "link":"#"
},
{
    "id":"thanjavur",
    "name":"Thanjavur",
    "img":"./assets/images/blog/thanja.jpg",
    "desc":"Thanjavur (Tanjavur or Tanjore) is a temple site in the Tamil Nadu region of southern India. which is considered to be the granary and ‘rice bowl’ of Tamil Nadu. Thanjavur was the capital of the great Chola (Cola) king Rajaraja I, Thanjavur one of the most important and most visited historical sites in India today. The gopuras at Thanjavur are two huge monumental gateways which lead to the compound dominated by the Brihadishvara temple.",
    "link":"#"
},
{
    "id":"ooty",
    "name":"Ooty",
    "img":"./assets/images/blog/oo.jpg",
    "desc":"Udhagamandalam abbreviated as Udhagai and Ooty listen is a town and municipality in the Indian state of Tamil Nadu. It is located 80 km north of Coimbatore and is the capital of the Nilgiris district. It is a popular hill stationlocated in the Nilgiri Hills. Originally occupied by the Toda, the area came under the rule of the East India Company at the end of the 18th century. The economy is based on tourism and agriculture, along with the manufacture of medicines and photographic film. The town is connected by the Nilgiri ghat roads and Nilgiri Mountain Railway. Its natural beauty attracts tourists and it is a popular summer destination. As of 2011, the town had a population of 88,430. Ooty is situated in the Nilgiri hills. The name meaning blue mountains in Tamil, Kannada and Badaga and most other Indian languages might have arisen from the blue smoky haze given off by the eucalyptus trees that cover the area or from the Kurunji flower, which blooms every twelve years and gives the slopes a bluish tinge. Because of the beautiful mountains and green valleys, Ooty became known as the Queen of Hill Stations.",
    "link":"#"
},
{
    "id":"kanyakumari",
    "name":"Kanyakumari",
    "img":"./assets/images/blog/kan.jpg",
    "desc":"This area is very beautiful. Since the early 1970s, tourism has been an industry in the town. Because of this it is one of the few small towns in South India where one can hear many of the different languages of India spoken in the street.Of late, the promotion of tourism has increased, with increasing emphasis on attractions outside the town, such as the surrounding landscapes, as well as the historical and religious sites found around the district. Ultimately a total of 1.9 million tourists (domestic and foreign) visited Kanyakumari in 2007.Though there are several places of tourist-interest in the town and district, Kanyakumari is especially popular in India for its spectacular and unique sunrise and sunset, thanks to its being nearly surrounded by waters. On balmy, full-moon evenings (locally called Chitra Pournami), one can also see the moon-rise and sunset at the same time.",
    "link":"#"
},
{
    "id":"tiruchendur",
    "name":"Tiruchendur",
    "img":"./assets/images/blog/trichen.jpg",
    "desc":"Tiruchendur is a municipality located in Thoothukudi District, Tamil Nadu, India. It is home to the Arulmigu Subramaniya Swamy Temple, which is one of the Six Abodes of Murugan. It is also the only abode of Murugan that is situated on a seashore.",
    "link":"#"
} */
];

export const testVideo = [
    {
      id:1,
      name: "Awareness of Village Vision Ventures Foundation",
      content:"https://www.youtube.com/embed/QrjU2qnnN1s"
    },
    {
      id:2,
      name: "Village Vision Ventures Foundation",
      content:"https://www.youtube.com/embed/Sz6PVcfV05w"
    }
  ];

  export const blogDetails=[
    {
      "id":"kothandaramaswamy-temple",
      "name":"Kothandaramaswamy Temple",
      "desc":"An approximately 500 year old temple located at the southernmost tip of this island, Kothandaramaswamy Temple is yet another pilgrimage destination in Rameswaram. The temple is very close to the sea and appears as an ideal place to trace the footprints of Rama. The temple here has the idols of Rama, Sita and Laxman along with Vibhishan. It is believed that Rama did the last rites of Pattabhishekam of Vibhishan in this place. The temple walls are scribled with the paintings depicting the same story.",
      "price":"Rs.12,900",
      "img":"./assets/images/places/temple-img.jpg"
  },
  {
    "id":"villoondi-theertham",
    "name":"Villoondi Theertham",
    "desc":"<p>Villoondi theertham is a famous tourist attraction in Rameshwaram . It is one of the beaches and also one of the 64 theerthas (sacred water body) in Rameswaram. It is situated 6 Km far from Rameswaram main bus stand. Villoondi theertham beach is a calm place with the silent tides. There is a good water spring situated inside the sea ,it is the main attraction of this place. (Sometimes sea water mixed with that spring water and makes it saline).</p><p> There is a small Shiva shrine near to the Villoondi theertham . The shiva lingam here is named as Thrayambakeshvarar.</p> <p>The word “villoondi “ literally means “the place pierced by arrow “ and Theertham means sacred water in Tamil language.</p>",
    "price":"Rs.8,900",
    "img":"./assets/images/places/villoondi-theertham.jpg"
},
{
    "id":"ariyaman-beach",
    "name":"Ariyaman Beach",
    "desc":"<p>Ariyaman Beach is situated on the side of the Palk Bay in the Ramanathapuram District. The Ariyaman Beach also features children's park, watch tower and water-based attractions like wind surfing and water scooters. Tourists can also visit the aquarium and museum near the beach. This clean beach features large casuarina trees that provide shade for rest or picnic.</p><p> The beach is located at a distance of 27 km from the city and measures around 150 metres in width. It extends to a stretch of 2 km and offer boating facility and much more to tourists. Parasailing is a famous activity at this beach, which is done using a parachute. This parachute takes the adventurers 200 metres above the water level and is controlled by a moving boat.</p> <p>There are numerous dormitories and cottages available near this beach that offer accommodation to tourists at reasonable prices. An estimation done by the Tourism Department shows that nearly 5000 local people visit this beach on weekends and on festivals.</p>",
    "price":"Rs.16,400",
    "img":"./assets/images/places/ariyaman-beach.jpg"
},
{
    "id":"kalam-memorial",
    "name":"Dr. APJ Abdul Kalam's Memorial",
    "desc":"<p>Dr.APJ Abdul Kalam Memorial in Rameswaram island becomes one of the important tourist attraction among the tourists and pilgrims who visit Rameswaram. Dr.APJ Abdul Kalam, popularly known as the “Missile Man of India”, was the 11th President of India between 2002 and 2007.</p><p> He died of a cardiac arrest on July 27, 2015 in Shillong where he had gone to deliver a lecture. In the memory of our beloved people president Abdul Kalam , there is a grand memorial monument built over his tomb to honour him. Dr. APJ Abdul Kalam National Memorial in the Rameswaram island becomes one of the Must Visit top ten places places to visit in the Rameshwaram for the tourists.</p> <p>Dr.Kalam’s worldly body rest in the peace beneath this monument’s central place, and his aura filled everywhere in this memorial through his thoughts.</p><p>Dr.APJ Abdul Kalam memorial showcases Dr APJ Abdul Kalam’s history. Personal belongings used by him, his portraits and his statues.</p><p>Statue of the our former President Dr. APJ Abdul Kalam, playing the musical instrument Veena is placed in the center of the memorial. There are two other silicon statues of Dr. Kalam, in presidential suit, in sitting and standing postures.</p><p>Sample model of the Agni missile placed in the Garden, which lies at the backyard of the Memorial.</p>",
    "price":"Rs.18,900",
    "img":"./assets/images/places/abdulkalam.jpg"
},
{
    "id":"five-faced-hanuman-temple",
    "name":"Five Faced Hanuman Temple",
    "desc":"This is a popular shrine in Rameswaram, located just a few kilometres away from the revered Ramanathaswamy Temple. It is dedicated to the Hindu deity Hanuman - the Monkey God. The shrine also houses statues of Lord Rama and Goddess Sita, which were installed here in 1964.",
    "price":"Rs.32,900",
    "img":"./assets/images/places/Five-face-anjaneya.jpg"
},
{
    "id":"pamban-bridge",
    "name":"Pamban Bridge",
    "desc":"<p>The Pamban Bridge, is a railway bridge on the Palk strait which connects the town of Rameswaram on Pamban Island to mainland India. The bridge refers to both the road bridge and the cantilever railway bridge, though primarily it means the latter. Opened on 24 February 1914, it was India's first sea bridge, and was the longest sea bridge in India until the opening of the Bandra worli sea link in 2010. The rail bridge is for the most part, a conventional bridge resting on concrete piers, but has a double leaf bascule section midway, which can be raised to let ships and barges pass through.</p><p>The railway bridge is 6,776 ft (2,065 m) long.   It was opened on 24 February 1914, construction having begun in 1911. However plans for a bridge had been suggested from as early as 1870 as the British Administration sought ways to increase trade with Ceylon. The bridge has a still-functioning double-leaf bascule section that can be raised to let ships pass.</p><p>The adjacent road bridge was opened in 1988. The railway bridge historically carried metre gauge trains, but Indian Railways upgraded the bridge to carry broad gauge trains as part of Project Unigauge, work that was completed on 12 August 2007. Until recently, the two leaves of the bridge were opened manually using levers by workers. Around 10 ships - cargo carriers, coast guard ships, fishing vessels and oil tankers - pass through the bridge every month. More work was carried out on the bridge in 2009 to strengthen it to enable it to carry goods trains.</p><p>After completion of the bridge, metre-gauge lines were laid from Mandapam up to Pamban station. From here the railway line bifurcated, one line towards Rameshwaram about 6.25 miles (10.06 km) up and another branch line of 15 miles (24 km) terminating at Dhanushkodi.</p>",
    "price":"Rs.24,400",
    "img":"./assets/images/places/pampans.jpg"
},
{
    "id":"ramarpatham",
    "name":"Ramarpatham",
    "desc":"<p>Ramarpatham is an important tourist place in Rameswaram. Ramar patham is also spelled  as Ramar Padam or Ramar padham or Gandhamadhana Parvatham. It is believed that, from this place rama oversee srilanka in the legendary of Ramayanam. Ramar patham has the foot print of Lord Rama and is a sacred place for Hindus. Tourists are visiting Ramar padham and do prayers in this temple.From this Ramar patham, one can get the full view of Rameswaram and a good view of dhanushkodi. In the first floor of Ramar Patham we can see the imprint of Ramar patham in a chakra. You can see the photo of the Ramars inprint in the below. Ramarpatham, an amazing location, is a hillock situated about 3 kms from the temple of Rameswaram. The hillock provides an amazing view of the entire Rameswaram Island and also has an imprint of Lord Rama’s feet on a chakra.</p><p>This is the highest altitude of rameswaram as per the old historical representations.</p>",
    "price":"Rs.24,400",
    "img":"./assets/images/places/ramar-patham.jpg"
},
{
    "id":"agnitheertham",
    "name":"Agnitheertham",
    "desc":"<p>Sri Ramanathaswamy temple faces east. The seashore, about 100 metres from the main entrance of the temple, is very calm and therefore ideal for bathing. The water at this place is considered sacred and known as Agni theertham. Pilgrims perform poojas in honour of their ancestors at this seashore.Theethams are shallow waters of the sea extending upto some metres. There are 22 such theerthams in Rameshwaram. Hindus believe that a bath in all of these theerthams will remove our sins. In legend, Lord SriRam took a bath in Agni theertham after killing Ravana and worshipped the deity here. Hence these theerthams are considered as holy for pilgrims.Agnitheertham, holding religious significance for Hindus, is situated at a distance of 100 metres from the Ramanathaswamy Temple. This place is located towards the east of the main entrance of the temple and is a suitable place for bathing. The water in Agnitheertham is considered to be sacred and many pilgrims perform pooja at this seashore for honouring their ancestors.</p><p>As per legend, it is believed that Agnitheertham is the spot where Lord Rama offered his prayers to Lord Shiva, after killing Ravana. Shiva devotees across the globe take a holy dip in the Agnitheertham for washing away their sins. The visit to the Ramanathaswamy Temple is often preceded by a bath at this spot.</p>",
    "price":"Rs.24,400",
    "img":"./assets/images/places/agni-theertham.jpg"
},
{
    "id":"dhanushkodi",
    "name":"Dhanushkodi",
    "desc":"<p>Dhanushkodi is situated to the South-East of Pamban. Danushkodi is about 18 miles (29 km) West of Talaimannar in Sri Lanka. The Dhanushkodi railway line running from Pamban Station was destroyed in the 1964 cyclone and a passenger train with over 100 passengers drowned in the sea.</p><p>The Hindu scripture Ramayana says that Lord Rama built a bridge or causeway, called Ram Setu or 'Rama's bridge', between the mainland and Sri Lanka, in order to bring his army across. After Rama won the war and crowned a new king of Lanka, Vibishna, requested Rama to destroy the bridge. Rama broke the bridge with one end of his bow. Hence, the name Dhanushkodi or 'end of the bow' (dhanush meaning 'bow' and kodi meaning 'end'). It is also said that Rama originally marked the spot for the bridge with one end of his famous bow that he strung to marry Princess Sita. The series of rocks and islets currently found in a line between India and Sri Lanka suggests there was indeed a former land connection between India and Sri Lanka.  The Kodhanda Ram Kovil temple marks the place where Rama is said to have begun his journey to Lanka.</p><p>Hindu pilgrims usually bathe in the ocean here before completing the pilgrimage to Rameswaram. The spot is considered a sacred confluence of the Bey of Bengal and the Indian Ocean. In addition, it is said that pilgrimage to the holy city of Kashi in North India is not complete without also worshipping at Rameswaram, including the ritual bath at Dhanushkodi.</p>",
    "price":"Rs.24,400",
    "img":"./assets/images/places/dhanushkodi.jpg"
},
{
    "id":"ramanathaswamy-temple",
    "name":"Ramanathaswamy Temple",
    "desc":"<p>Ramanathaswamy Temple is a Hindu temple dedicated to god Shiva located on Rameshwaram island in the state of Tamil nadu, India. It is also one of the twelve Jyothirlinga temples, where Shiva is worshipped in the form of a Jyotirlingam (English: pillar of light).It is one of the 275 Paadal petra sthalams, where the three of the most revered Nayanars (Saivite saints), Appar Sundarar and Tirugnana Sambandar, have glorified the temple with their songs. The temple was expanded during the 12th century by Pandya Dynasty, and its principal shrines were renovated by Jeyaveera Cinkaiarian and his successor Gunaveera Cinkaiariyan of the Jaffna Kingdom. The temple has the longest corridor among all Hindu temples in India. The temple is located in Rameswaram, an island town in South India, considered a holy pilgrimage site for Shaivites, Vaishnavites  and Smarthas.</p><p>According to the Ramayana, Rama, the seventh incarnation of god Vishnu, is believed to have prayed to Shiva here to absolve sin of killing a brahmana, committed during his war against the demon king Ravana in Sri Lanka. Rama wanted to have the largest lingam to worship Shiva. He directed Hanuman, the monkey lieutenant in his army, to bring the lingam from Himalayas. Since it took longer to bring the lingam, Sita, the wife of Rama, built a small lingam out of the sand available in the sea shore, which is believed to be the lingam in the sanctum.</p>",
    "price":"Rs.24,400",
    "img":"./assets/images/places/rameswaram-temple1.jpg"
}
];


export const myImageDetails=[
    {
        "id":"kanyakumari",
        "name":"Kanyakumari",
        "img":"./assets/images/blog/kan.jpg"
    },
{
    "id":"thenkasi",
    "name":"Thenkasi",
    "img":"./assets/images/blog/ker.jpg"
},
{
    "id":"covai",
    "name":"Covai",
    "img":"./assets/images/blog/kodai.jpg"
},
    {
      "id":"nilagiri",
      "name":"Nilagiri",
      "img":"./assets/images/blog/oo.jpg"
  },
  {
    "id":"theni",
    "name":"Theni",
    "img":"./assets/images/blog/theni.jpg"
},
{
    "id":"mdu",
    "name":"Madurai",
    "img":"./assets/images/blog/mdu.jpg"
},
{
    "id":"rmm",
    "name":"Rameswaram",
    "img":"./assets/images/blog/r1.jpg"
},
{
    "id":"tjr",
    "name":"Thanjavur",
    "img":"./assets/images/blog/thanja.jpg"
},
{
    "id":"thirunelveli",
    "name":"Thirunelveli",
    "img":"./assets/images/blog/thirunelveli.jpg"
}
/*{
    "id":"tavera",
    "name":"Tavera",
    "img":"./assets/images/gallery/c3.jpg"
}*/
];
