import '../scss/footer.scss';
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import SocialButton from "../components/SocialButton";

function FooterPage() {
  return (
    <div className="footer">
    <section>
    <div className="container">
      <div className="row">
      <div className="col-lg-3 col-md-6 globeXImage" style={{marginTop:'10px'}}>
      <img loading="lazy" src="./assets/farm.gif" className='globeImg' alt="service-icon" style={{width:'230px'}} />
      </div>
        <div className="col-lg-3 col-md-6" style={{marginTop:'10px'}}>
          <h4><b><span className="fLogoTitle">Village Vision Ventures Foundations</span> </b> <br/>
          <span className="fLogoSubtitle">Section 8 No. 136411/2022 </span></h4>
          <p className="fSubText">
            No 4/221-2 First Floor, Maravar Colony, Ambai Main Road, Kooniyar(po) Seranmahadevi, Thirunelveli-627417.<br/>
            Phone: +91 9042216166 <br/>
            Email: info@vvvfoundations.org
          </p>
         
        </div>
        <div className="col-lg-3 col-md-6" style={{marginTop:'10px'}}>
        <h3 className="fLogoTitle">Quick Links</h3>
          <div className="fSubText">
            <Link to='/#' className='fSubTextLink'>About</Link> 
            <br/> 
            <Link to='/#' className='fSubTextLink'>Projects</Link>
            <br/> 
            <Link to='/#' className='fSubTextLink'>Our Family</Link>
            <br/> 
            <Link to='/gallery' className='fSubTextLink'>Gallery</Link>
            <br/> 
            <Link to='/contact' className='fSubTextLink'>Contact Us</Link> 
            <br/> 
            <Link to='/privacy' className='fSubTextLink'>Privacy Policy</Link>  
            <br/> 
            <Link to='/termcondition' className='fSubTextLink'>Terms and Condition</Link> 
          </div>
        </div>
        <div className="col-lg-3 col-md-6" style={{marginTop:'10px'}}>
          <h3 className="fLogoTitle">Woring Hours</h3>
          <div className="fSubText">
            <table>
              <thead></thead>
              <tbody>
              <tr>
              <td>Monday - Saturday</td>
              </tr> 
              <tr>
              <td>10:00 AM - 6:00 PM</td>
              </tr>                   
              </tbody>
              <tfoot>
              <h4><span className="fLogoSubtitle">Follow us on </span></h4>
                <SocialButton/>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    </section>

  <section className="copyright" style={{backgroundColor : 'green',color:'lightgreen' }}>
    <div className="container footerText">
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="copyright-info text-center text-md-center">
            <span>Copyright &copy; 
               ratchu{ new Date().getFullYear() }
              ,  <img src="./assets/sign.png" alt="Ratchus" className='myIcon'/>  Powered by Ratchus</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ScrollToTop smooth style={{backgroundColor:'lightgreen', color:'green'}}/>
  </div>
  )
}

export default FooterPage