import '../scss/navbar.scss';
import { Link } from 'react-router-dom';
import{FaBars,FaTimes,FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { CiBank } from "react-icons/ci";
import { useState } from 'react';

function NavBar() {
  const [icon,SetIcon]=useState(false);

  const handleClick=()=>{
    SetIcon(!icon);
   };

   const closeSideDrawer=()=>{
    SetIcon(false);
   };

  return (
    <>
    <div className='topper'>
  <ul className='infoBox'>
  <li>
      <Link to={`/paymentoption`}  className='infoText'>
        <CiBank className='infoIcon'/>
        <span id='lbl'> Donate </span>
        </Link>
    </li>
    <li>
      <a href={`mailto:${'info@vvvfoundations.org'}`}  className='infoText'>
        <FaEnvelope className='infoIcon'/>
        <span id='lbl'> info@vvvfoundations.org</span>
        </a>
    </li>
    <li>
      <a href={`tel:${'+919042216166'}`} className='infoText'>
        <FaPhoneAlt className='infoIcon'/>
        <span id='lbl'> +91 9042216166</span>   
        </a>
    </li>
    <li>    
      <a
          href="https://wa.me/+918903510960"
          target="_blank"
          rel="noopener noreferrer"  className="infoText"
        >
        <img src="./assets/icon-image/wappicons.png" alt="Rameswaram Sri Balaji" className='infoWaIcon'/></a>
    </li>
  </ul>
</div>
    <nav className='navBar'>
    <Link className="navLogo" data-no-turbolink="true" to="/" onClick={closeSideDrawer} > <img src="./assets/logo.png" alt="Rameswaram Sri Balaji" className='logoImg' /></Link>
    <div className="menuIcon" onClick={handleClick}>
      {icon?<FaTimes className='faTimes'/>:<FaBars className='faBars'/>}
    </div>
      <ul className={icon? 'navMenu active' :'navMenu'}>
        <li>
      <Link className="navLink" data-no-turbolink="true" to="/" onClick={closeSideDrawer}>Home</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/about" onClick={closeSideDrawer}>About</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/#" onClick={closeSideDrawer}>Echo Third world war</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/products" onClick={closeSideDrawer}>Projects</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/#" onClick={closeSideDrawer}>You Can Support us</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/#" onClick={closeSideDrawer}>Our Family</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/gallery" onClick={closeSideDrawer}>Gallery</Link>
      </li>
      <li>
      <Link className="navLink" data-no-turbolink="true" to="/contact" onClick={closeSideDrawer}>Contact us</Link>
      </li>
      <li>
      <Link className="navLink applyNow" data-no-turbolink="true" to="/contact" onClick={closeSideDrawer}>Join</Link>
      </li>
      </ul>
    </nav>

    </>
  )
}

export default NavBar;