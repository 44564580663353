import '../scss/contact.scss';
import {  useState,useRef } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaWhatsapp,FaClock } from 'react-icons/fa';
import Slider from 'react-slick';
import LoadingBar from "react-top-loading-bar";
import { saveApi } from '../services/appApi';
import { passwd } from '../services/auth';

function ContactPage() {

  const toastOptions = {
    autoClose: 700,
    pauseOnHover: true,
  }
  const ref = useRef(null);

  const form = useRef();
  const [data, setData] = useState("");

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”
    ref.current.continuousStart();
    setData({ ...data,
      'contact_name': form.current.contact_name.value,
      'contact_email': form.current.contact_email.value,
      'my_email': 'info@vvvfoundations.org',
      'contact_mobile_number': form.current.contact_mobile_number.value,
      'contact_location': form.current.contact_location.value,
      'contact_host':'mail.sribalajirameswaram.com',
      'contact_message': form.current.contact_message.value,
      'contact_pwd': passwd,
      'user_id':'Ratchus' });

    console.log();
    saveApi(data,'mail').then((result) => {
      if (result) {
        toast(result.data.msg, toastOptions);
        ref.current.complete();
      } else {
        toast('Invalid Request', toastOptions);
        ref.current.complete();
      }
    });
  };


  const slides = [
    { url: 'assets/slider-main/bg4.jpg', head: '', title: '', subTitle: '' },
  ];

  let mainSettings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };


  return (
    <div className="body-inner" id="contact">
       <LoadingBar color="#f11946" ref={ref} />
      <section className="banner-carousel banner-carousel-1 mb-0">
        <div className='contactHomeText'>
          <h1>Contact Us</h1>
          <Link to='/' className='homeTextLink'>Home</Link> | Contact Us
        </div>
        <ToastContainer />
        <div className='mainContainerStyles'>
          <Slider {...mainSettings}>
            {slides.map((image) => (
              <img loading="lazy" className='contactImgContainer' src={image.url} alt='Ratchu Software' />
            ))}
          </Slider>
        </div>
      </section>
      <section id="main-container" className="infoContainer">
        <div className="container">
          <div className='contactTitleBox'>
            <div className="row text-center">
              <div className="col-lg-12">
                <h2 className="contactTitle">Get In Touch!</h2>
              </div>
            </div>
          </div>
          <div id="contact_form" className="row">
            <div className="col-md-6">
              <div className="tsContact">
              <span className="ts-service-icon d-flex">
                  <FaClock />
                  <h6>Operating Hours</h6>
                </span>
                <p className='boxText'>
                Monday - Saturday: 10:00 AM - 6:00 PM
                </p>
              </div>

              <div className="tsContact">
                <span className="d-flex">
                  <FaMapMarkerAlt />
                  <h6>Address</h6>
                </span>
                <p className='boxText'>
                No 4/221-2 First Floor, Maravar Colony, Ambai Main Road, Kooniyar(po), Seranmahadevi, Thirunelveli-627417.
                 </p>
                <p className='boxText'>
               
                </p>
                <p className='boxText'>
                 Tamilnadu,India.
                </p>
              </div>
              <div className="tsContact">
                <span className="d-flex">
                  <FaEnvelope />
                  <h6>Email</h6>
                </span>
                <p className='boxText'>info@vvvfoundations.org</p>
              </div>
              <div className="tsContact">
                <span className="d-flex">
                  <FaPhoneAlt />
                  <h6>Call Us</h6>
                </span>
                <p className='boxText'>+91 9042216166</p>
                <span className="d-flex">
                  <FaWhatsapp />
                  <h6>WhatsApp</h6>
                </span>
                <p className='boxText'>+91 8903510960</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className='contextForm'>
              <form ref={form} onSubmit={sendEmail} accept-charset="UTF-8" id='mail_contact_form'>
                    <div className="form-group">
                      <label>Name</label>
                      <input className="form-control form-control-name" required="required" type="text" name="contact_name" id="contact_name" />
                    </div>
                    
                    <div className="form-group">
                      <label>Email</label>
                      <input className="form-control form-control-name" type="text" name="contact_email" id="contact_email" />
                    </div>

                    <div className="form-group">
                      <label>Mobile Number</label>
                      <input className="form-control form-control-name" required="required" type="text" name="contact_mobile_number" id="contact_mobile_number" />
                    </div>

                    <div className="form-group">
                      <label>Location</label>
                      <input className="form-control form-control-name" required="required" type="text" name="contact_location" id="contact_location" />
                    </div>

                    <div className="form-group">
                      <label>Message</label>
                      <textarea className="form-control form-control-message" rows="3" name="message" id="contact_message">
                      </textarea>
                    </div>

                    <div className="text-center"><br />
                      <input type="submit" name="commit" value="Submit" className="btn btn-primary solid blank" data-disable-with="Send" />
                    </div>
              </form>
              </div>      
              </div>

          </div>
        </div>
      </section>
      <section id="main-container" className="infoContainer">
        <div className="container">
      <div className="contactSecBox">
          <div className='row'>
            <div className="col-md-12">
              <div className='contactTitleBox'>
                <h2 className="contactTitle">Find Our Location</h2>
              </div>
              <iframe title='Get In Touch!' src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d521.9255462916096!2d77.54993026001762!3d8.679569065937969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1740187495810!5m2!1sen!2sin" style={{ width: '100%', height:'420px',  border: '0px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
            </div>
          </div>
        </div>
        </div>
      </section>
      <div className='infoContainer'></div>
      <section className="infoContainer" style={{ backgroundColor: 'green' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subscribe-call-to-acton">
                <p/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactPage