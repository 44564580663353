import '../scss/service.scss';
//import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from 'react-slick';
import ImageSlider from '../ImageSlider';

function ServicePage() {

  //let navigate=useNavigate();
  //const handleClick =  (e) => navigate('/contact'); //toast("Currently Myvisapriority.com doesn't provide online visa application to "+ e.target.name +", Kindly call us to get receive more information from our Visa Specialists, Please contact us on +971 55 636 8548");
  const slides=[
    {url:'assets/slider-pages/img1.png', head:'',title:'',subTitle:''}
  ];

  const mainSlides=[
    {url:'assets/slider-main/bg4_4.jpg', head:'',title:'',subTitle:''},
  ];
  let mainSettings = {
    infinite: true,
    autoplay:true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 960,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        mainSettings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const containerStyles={

    margin:'0 auto',
    objectFit: 'cover'
  }

  return (
    <div className="body-inner" id="services">
    <section className="banner-carousel banner-carousel-1 mb-0">
    <div className='serviceHomeText'>
              <h1>Support us</h1>
              <Link to='/' className='homeTextLink'>Home</Link> | Support us
    </div>
      <ToastContainer style={{width:'100%'}}/>
<div className='mainContainerStyles'>
<Slider {...mainSettings}>
      {mainSlides.map((image) => (
                      <img loading="lazy" className='serviceImgContainer' src={image.url} alt='Ratchu Software' />
              ))}
      </Slider>
</div>
</section>

<section id="main-container" className="infoContainer">
<div className="container aboutSecBox">
<div className="row text-center">
      <div className="col-lg-12">
        
      </div>
  </div>
  <div className="row">
      <div className="col-lg-6">
      <h2 className="secMidTitle">You Can Support us</h2>
        <p className='aboutBoxText'> </p>
      </div>
      <div className="col-lg-6 mt-5 mt-lg-0">
        <div style={containerStyles}  className="aboutImg">
           <ImageSlider slides={slides}/>
        </div>    
      </div>
  </div>
</div>
</section>

<section className="infoContainer" style={{background:"green"}}>
<div className="container">
  <div className="row">
      <div className="col-md-12">
        <div className="subscribe-call-to-acton">
            <p/>
        </div>
      </div>
  </div>
</div>
</section>

<section id="main-container" className="infoContainer">
<div className="container aboutSecBox">
<div className="row text-center">
      <div className="col-lg-12">
        
      </div>
  </div>
  <div className="row">
  <div className="col-lg-6 mt-5 mt-lg-0">
  <div className='aboutMidBoxText'>
    <h3 className='boxTitle'>Support us</h3>
  <ul >
           <li></li>
           
           </ul>
        </div>     
      </div>
      <div className="col-lg-6">
        <p className='aboutBoxText'> </p>
        <ul>
        <li></li>
        </ul>
    
      </div>
     
  </div>
</div>
</section>

<section className="infoContainer" style={{background:"green"}}>
  <div className="container">
    <div className="row">
        <div className="col-md-12">
          <div className="subscribe-call-to-acton">
              <p/>
          </div>
        </div>
    </div>
  </div>
</section>
  </div>
  )
}

export default ServicePage