import '../scss/about.scss';
import { Link } from 'react-router-dom';
import ImageSlider from '../ImageSlider';
import Slider from 'react-slick';

function AboutPage() {

   /* const myFunction=()=> {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }
  
  const myFunction1 =() => {
    var dots = document.getElementById("dots1");
    var moreText = document.getElementById("more1");
    var btnText = document.getElementById("myBtn1");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }
  
  const myFunction2=()=> {
    var dots = document.getElementById("dots2");
    var moreText = document.getElementById("more2");
    var btnText = document.getElementById("myBtn2");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }
  const myFunction3=()=> {
    var dots = document.getElementById("dots3");
    var moreText = document.getElementById("more3");
    var btnText = document.getElementById("myBtn3");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  } */

    const slides=[
      {url:'assets/slider-pages/img1.png', head:'',title:'',subTitle:''}
    /*  {url:'assets/slider-pages/img2.jpg', head:'',title:'',subTitle:''},
      {url:'assets/slider-pages/img3.jpg', head:'',title:'',subTitle:''},
      {url:'assets/slider-pages/img4.jpg', head:'',title:'',subTitle:''} */
    ];
  
    let mainSettings = {
      infinite: true,
      autoplay:true,
      speed: 1000,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
  
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    };
  
    const mainSlides=[
      {id:1,url:'assets/slider-main/bg4_4.jpg', head:'',title:'',subTitle:''},
    ];
  
  const containerStyles={
  
    margin:'0 auto',
    objectFit: 'cover'
  }


  return (
    <div  className="body-inner" id="about">
      <section className="banner-carousel banner-carousel-1 mb-0">
      <div className='aboutHomeText'>
                <h1>About</h1>
                <Link to='/' className='homeTextLink'>Home</Link> | About
      </div>
       <div className='mainContainerStyles'>
            <Slider {...mainSettings}>
        {mainSlides.map((image) => (
                        <img loading="lazy" key={image.id} className='aboutImgContainer' src={image.url} alt='Ratchu Software' />
                ))}
        </Slider>
          </div>
      </section>
  <section id="main-container" className="infoContainer">
  <div className="container aboutSecBox">
  <div className="row text-center">
        <div className="col-lg-12">
          
        </div>
    </div>
    <div className="row">
        <div className="col-lg-6">
        <h2 className="secMidTitle">Village Vision Ventures Foundations</h2>
          <p className='aboutBoxText'> ... </p>
         
        </div>
        <div className="col-lg-6 mt-5 mt-lg-0">
          <div style={containerStyles} className="aboutImg">
             <ImageSlider slides={slides}/>
          </div>    
        </div>
    </div>
  </div>
</section>

<section className="infoContainer" style={{ backgroundColor: 'green' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subscribe-call-to-acton">
                <p/>
              </div>
            </div>
          </div>
        </div>
      </section>
      
<section id="info-container" className="infoContainer">
  <div className="container aboutSecBox">
    <div className="row">
    <div className="col-lg-4 col-md-6 mb-5 ">
    <div className='aboutMidBoxText'>
      <h3 className='boxTitle'>Resources</h3>
    <ul>
             <li>Village Vision Venture Foundation</li>
              
             </ul>
          </div>  
        </div>
        <div className="col-lg-8 col-md-6 mb-5">
        <h3 className="secTitle">Why Choose Us</h3>
        <p className="aboutBoxText"><b></b>  </p>
        <p className='aboutBoxText'> </p>
        <p className='aboutBoxText'></p>
          
          <p className='aboutBoxText'> <b> </b> </p>
          <p className='aboutBoxText'></p>
          <p className='aboutBoxText'></p>
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
<section className="infoContainer" style={{ backgroundColor: 'green' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="subscribe-call-to-acton">
                <p/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
  )
}

export default AboutPage