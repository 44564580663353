
export const isAuthenticated = ()=>{
    const current = new Date();
   // const exDate = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    const edate = `${current.getDate()}`;
   // const month = `${current.getMonth()}`;
   // const year = `${current.getFullYear()}`;
    //const eDate = `${current.getDate()+2}/${current.getMonth()+1}/${current.getFullYear()}`;
    return edate?true:false;
}

export const passwd="Ratchu3@Soft";

//Voice@123
export const bankName=[
    {
      "id":101,
      "label":"Bank Name",
      "name":"XXxX",
    }
];

export const bankDetails=[
    {
      "id":101,
      "label":"Account Name",
      "name":"XXxX",
    },
    {
        "id":102,
        "label":"Account No",
        "name":"XXxX",
      },
      {
        "id":103,
        "label":"IFSC Code",
        "name":"XXxX",
      },
      {
        "id":104,
        "label":"MICR Code",
        "name":"XXxX",
      },
      {
        "id":105,
        "label":"Account Type",
        "name":"XXxX",
      },
      {
        "id":106,
        "label":"Branch Address",
        "name":"XXxX",
      },
      {
        "id":107,
        "label":"Contact Number",
        "name":"+91 XXxX",
      },
      {
        "id":108,
        "label":"UBI(Gpay) Number",
        "name":"+91 XXxX",
      }
];