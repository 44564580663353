import '../scss/privacy.scss';

function PrivacyPage() {

  return (
    <div  className="body-inner"  id="privacy" >
      <section id="main-container" className="infoContainer">
  <div className="container privacySecBox">
    <div className="row">
        <div className="col-lg-12 who-we-are">
          <h4 className="secTitle">PRIVACY STATEMENT</h4>
          <p className='boxText'> </p>
          <p className='boxText'><b></b> </p>
        </div>
    </div>
  </div>
</section>
<div className='infoContainer'></div>
    </div>
  )
}

export default PrivacyPage
